import React from 'react';

// Components
import { Typography, Table } from 'antd';

// Sub components
const { Title } = Typography;
 
export default function Leaderboard(props) {
  const leaderboard = props.leaderboard;
  const company = props.company;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => a.score - b.score,
        multiple: 1,
      },
      key: 'score',
    }
  ];
  
  const dataSource = [];
  leaderboard.forEach((player, index) => (
    dataSource.push({
      key: "user"+index,
      name: player.nickname,
      score: player.score,
    })
  ));
  
  return (
    <div className="leaderboard-container">
      <Title level={3} style={{ paddingTop: 15, }}>Games Standings</Title>
      <Title level={4}>{company}</Title>
      <Table pagination={false} columns={columns} dataSource={dataSource}>
      </Table>
    </div>
  );
}