import React from 'react';

import { Typography, Button } from 'antd';

const { Title } = Typography;

const goHome = () => {
  window.location = "https://afino.io";
}

export default function FourOhFour() {
  return (
    <div className="site-layout-background" style={{ padding: 24, textAlign: 'center', }}><Title>Sorry we couldn't find the room from this link</Title>
      <Title level={4}>It could be invalid or expired</Title>
      <Button type="primary" onClick={goHome}>
        Home
      </Button>
    </div>
  )
}
