import React, { useState, useEffect } from 'react';
// Functional Libraries
import axios from 'axios'; // Http server requests
import io from 'socket.io-client'; // socket client library
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'; // Routing
import { Howl, Howler } from 'howler'; // Audio tool

// Internal pages
import Doodle from './Doodle';
import FourOhFour from './404';

// Constant definitions
import { APIbaseURL, emailRules } from './constants';

// Components
import Leaderboard from './Doodle/leaderboard';
import { Layout, Typography, Comment, Popconfirm, Button, Form, Input, Tooltip, Popover, message } from 'antd';
import { QuestionCircleOutlined, LoadingOutlined, SoundOutlined } from '@ant-design/icons';

// Media
import Logo from './media/logo.svg';
import BackgroundLines from './media/backgroundLines.svg';

// Stylesheets
import 'antd/dist/antd.less';
import './Doodle/Less/doodle.less';

import JoinedSound from './sounds/mp3/joined_game.mp3';
import JoinedSoundWebM from './sounds/webm/joined_game.webm';
import ReadySound from './sounds/mp3/ready.mp3';
import ReadySoundWebM from './sounds/webm/ready.webm';
import RoundStart from './sounds/mp3/round_finished.mp3';
import RoundStartWebM from './sounds/webm/round_finished.webm';

const joinedAudio = new Howl({
  src: [JoinedSound, JoinedSoundWebM],
});
const readyAudio = new Howl({
  src: [ReadySound, ReadySoundWebM],
});
const roundStartAudio = new Howl({
  src: [RoundStart, RoundStartWebM],
});

// Subcomponents
const { Content, Footer } = Layout;
const { Title, Text } = Typography;

// Grab the game ID from the URL
const gameID = new URLSearchParams(window.location.search).get('id');

// Message to anyone inspecting the game through dev tools
console.log(
  '%cHey there curious techy friend!!\nYes you might be able to figure out how to cheat from here but you will also probably break the game for the others playing with you.\nThis game is still in development and so if you notice any major flaws or have suggestions for improvements shoot me an email at: devin@afino.io',
  'color: #6AE9B2; font-size:18px'
);

export default function App() {
  const [mute, setMute] = useState(false); // Mute all sounds
  const [loading, setLoading] = useState(true); // Display loading indicator while finding event
  const [nickname, setNickname] = useState(localStorage.getItem('nickname'));
  const [leaderboard, setLeaderboard] = useState([]); // Stores the players and scores
  const [gameState, setGameState] = useState(); // Current game state if rejoin
  const [company, setCompany] = useState(''); // Company name
  const [gameOver, setGameOver] = useState(false); // Whether the game is already over
  const [socket, setSocket] = useState(); // Game Socket

  // Waiting Room
  const [lobby, setLobby] = useState([]);
  const [readyVisible, setReadyVisible] = useState(true);

  // Mute all sounds if mute toggled on
  Howler.mute(mute);

  useEffect(() => {
    if (gameID) {
      axios
        .get(APIbaseURL + 'api/eventById', {
          params: {
            gameID: gameID,
            doodleID: localStorage.getItem('doodleID'),
          },
        })
        .then(res => {
          setLoading(false);
          if (res.data.message === 'Success') {
            setCompany(res.data.company);
            if (res.data.rejoin) {
              console.log('Try to reconnect');
              setSocket(io(APIbaseURL + 'doodle', { query: { doodleID: localStorage.getItem('doodleID'), gameID: gameID } }));
            }
          } else {
            message.info(res.data.message);
            setGameOver(true);
            setLeaderboard(res.data.leaderboard);
            setCompany(res.data.company);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log(err.message);
          setCompany(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        console.log('Re saved socket id');
        localStorage.setItem('doodleID', socket.id);
      });
      socket.on('lobby', lby => {
        // Player joined
        if (lby.add) {
          joinedAudio.play();
          setLobby(lby.lobby);
        }
        // Ready up or leave lobby
        else {
          readyAudio.play();
          setLobby(lby);
        }
      });
      socket.on('start game', score => {
        roundStartAudio.play();
        setLeaderboard(score);
      });
      // When rejoining game
      socket.on('rejoin', gameState => {
        setGameState(gameState);
      });
      // This fired when pushing game to db error
      socket.on('error', err => {
        message.error(err);
      });
      // This fired when middleware blocks user from connecting
      socket.on('connect_error', err => {
        message.error(err.message, 5);
        console.log('Socket Connect Error: ', err);
        socket.disconnect();
        setSocket(null);
      });
    }
  }, [socket]);

  const Game = () => {
    if (loading) return <LoadingOutlined style={{ fontSize: 45, marginLeft: '47vw', marginTop: '45vh' }} />;
    else if (gameOver)
      return (
        <Wrapper>
          <Leaderboard company={company} leaderboard={leaderboard} />
        </Wrapper>
      );
    else if (leaderboard.length > 1 || gameState)
      return <Doodle socket={socket} leaderboard={leaderboard} mute={mute} gameID={gameID} gameState={gameState} />;
    else if (socket)
      return (
        <Wrapper>
          <WaitingRoom />
        </Wrapper>
      );
    else if (company)
      return (
        <Wrapper>
          <JoinRoom />
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <FourOhFour />
        </Wrapper>
      );
  };

  const Wrapper = props => {
    return (
      <Layout
        className="site-layout"
        style={{
          minHeight: '100vh',
          background: '#f6f5f5 url(' + BackgroundLines + ') no-repeat center',
          backgroundSize: '200vw 100vh',
          backgroundPosition: '-30vw 0vw',
        }}
      >
        <img src={Logo} alt="logo" className="logo-main" />
        <SoundOutlined
          onClick={() => setMute(mute => !mute)}
          className="mute"
          style={{ top: leaderboard.length > 0 && 40, color: mute ? '#AAAAAA' : '#5D22A1' }}
        />
        <Content style={{ padding: 24, paddingTop: 80, textAlign: 'center' }}>{props.children}</Content>
        <Footer style={{ textAlign: 'center', padding: 5 }}>Afino ©2020 All Rights Reserved</Footer>
      </Layout>
    );
  };

  const WaitingRoom = () => {
    const startGame = () => {
      socket.emit('start game');
      //Create leaderboard from players in game
    };
    // Change status to ready
    const readyUp = () => {
      socket.emit('ready');
      setReadyVisible(false);
    };
    // Enable button only when everyone ready
    const everyoneReady = lobby.every(player => player.ready);

    return (
      <div style={{ width: 300, backgroundColor: 'white', padding: 15, display: 'inline-block', borderRadius: 15 }}>
        {readyVisible && (
          <Button type="primary" onClick={readyUp}>
            Ready Up
          </Button>
        )}
        {lobby.map((player, index) => (
          <Comment
            key={'player' + index}
            author={player.nickname}
            content={<p style={{ color: player.ready ? 'green' : 'red' }}>{player.ready ? 'Is ready' : 'Is not ready'}</p>}
          />
        ))}
        {(lobby.length > 1 && everyoneReady && (
          <Popconfirm
            title="Is everyone in?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={startGame}
            okText="Start"
            cancelText="No"
          >
            <Button type="primary" style={{ margin: 15 }}>
              Start
            </Button>
          </Popconfirm>
        )) || <Title level={5}>{everyoneReady ? 'Need more players' : "Not everyone's ready"}</Title>}
        <br />
        <Text type="secondary">
          {lobby.length}/12{' '}
          <Popover
            content={
              <>
                <Title level={5}>Have More Players?</Title>
                <Button href="https://app.afino.io/engage?uid=1" target="_blank">
                  Make Another Game
                </Button>
              </>
            }
          >
            <QuestionCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </Text>
      </div>
    );
  };

  const JoinRoom = () => {
    const onJoin = values => {
      setNickname(values.nickname);
      setSocket(io(APIbaseURL + 'doodle', { query: { gameID: gameID, nickname: values.nickname, email: values.email, doodleID: localStorage.getItem('doodleID') } }));
      localStorage.setItem('nickname', values.nickname);
    };

    return (
      <Form
        onFinish={onJoin}
        layout="vertical"
        style={{ width: 300, backgroundColor: 'white', padding: 15, display: 'inline-block', borderRadius: 15 }}
      >
        <Title level={3} style={{ paddingTop: 15 }}>
          Welcome to Doodle
        </Title>
        <Title level={4}>{company}</Title>
        <Form.Item label="Name" name="nickname" rules={[{ required: true, message: 'Enter a Name' }]} initialValue={nickname}>
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <>
              Work Email
              <Tooltip title="This is only used by your company admin">
                <QuestionCircleOutlined style={{ marginLeft: 5 }} />
              </Tooltip>
            </>
          }
          name="email"
          rules={emailRules}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Join
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Game />
        </Route>
        <Route>
          <Wrapper>
            <FourOhFour />
          </Wrapper>
        </Route>
      </Switch>
    </Router>
  );
}
