import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Howl, Howler } from 'howler'; // Audio library
import LZString from 'lz-string'; // String compression for sending drawing

// Sounds for the game
import CorrectSound from '../sounds/mp3/win.mp3';
import CorrectSoundWebM from '../sounds/webm/win.webm';
import IsTurn from '../sounds/mp3/your_turn.mp3';
import IsTurnWebM from '../sounds/webm/your_turn.webm';
import StartRound from '../sounds/mp3/round_start.mp3';
import StartRoundWebM from '../sounds/webm/round_start.webm';
import Ticker from '../sounds/mp3/timer.mp3';
import TickerWebM from '../sounds/webm/timer.webm';
import GameEnd from '../sounds/mp3/game_finished.mp3';
import GameEndWebM from '../sounds/webm/game_finished.webm';

// Functional components and icons
import CanvasDraw from '../canvas'; // Canvas component
import { HexColorPicker } from 'react-colorful'; // Color picker
import { Layout, Row, Col, Typography, Statistic, Button, Popover, Slider, Comment, Form, Input, Drawer, Modal, message, notification } from 'antd';
import {
  FormatPainterOutlined,
  HighlightOutlined,
  SmallDashOutlined,
  UndoOutlined,
  SmileOutlined,
  ClearOutlined,
  SoundOutlined,
  TrophyOutlined,
} from '@ant-design/icons';

// Imported logo
import Logo from '../media/logo.svg'; // Afino Logo
import { TurnDrawing } from '../constants'; // Drawing to show people when it's their turn

// Stylesheets
import 'react-colorful/dist/index.css';

// Sub component destructuring
const { Title, Text } = Typography;
const { Footer } = Layout;
const { Countdown } = Statistic;

// Sounds
// Sounds for the game
const correctSound = new Howl({ src: [CorrectSound, CorrectSoundWebM] });
const turnSound = new Howl({ src: [IsTurn, IsTurnWebM] });
const roundSound = new Howl({ src: [StartRound, StartRoundWebM] });
const tickerSound = new Howl({ src: [Ticker, TickerWebM] });
const endSound = new Howl({ src: [GameEnd, GameEndWebM] });

var almostOver;

if (window.location.href.includes('afino')) {
  document.domain = 'afino.io'; // Allow iframing for feedback iframe
}

// Initialize the canvas size
var prevDrawingWidth = window.innerWidth > 575 ? window.innerWidth - (window.innerWidth / 24) * 9 : window.innerWidth - (window.innerWidth / 24) * 1;
var prevDrawingHeight = window.outerHeight - 350;

export default function Doodle(props) {
  // Get socket and game info from props
  const { socket } = props;

  const canvas = useRef(); // Create reference to canvas
  const messageBox = useRef(); // Create reference for chat auto scrolling
  const [form] = Form.useForm(); // ref for the chat form
  const guessText = useRef(); // ref for the chat input box

  // Initalize states needed for game
  const [mute, setMute] = useState(props.mute); // Mute all the sounds
  Howler.mute(mute);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [brush, setBrush] = useState('#000000'); // Brush color
  const [brushSize, setBrushSize] = useState(2); // Brush radius
  const [lazyRadius, setLazyRadius] = useState(0); // 'Chain' between cursor and drawing point
  const [comments, setComments] = useState([]); // Comments array
  const [timer, setTimer] = useState(props.gameState ? new Date(props.gameState.end * 1000) : Date.now()); // Timer for round
  const [gameTimer, setGameTimer] = useState(props.gameState ? new Date(props.gameState.gameTime * 1000) : Date.now()); // Timer for the whole game
  const [word, setWord] = useState(props.gameState ? props.gameState.word : ''); // Word that they are drawing
  const [notTurn, setNotTurn] = useState(props.gameState ? props.gameState.notTurn : true); // Disable canvas elements when not turn
  const [noGuess, setNoGuess] = useState(props.gameState ? props.gameState.noGuess : false); // Disable guessing UI
  const [leaderboard, setLeaderboard] = useState(props.gameState ? props.gameState.leaderboard : props.leaderboard); // Holds leaderboard

  // Initialize the canvas size
  const drawingWidth = window.innerWidth > 575 ? window.innerWidth - (window.innerWidth / 24) * 9 : window.innerWidth - (window.innerWidth / 24) * 1;
  const drawingHeight = window.outerHeight - 350;

  const timeUp = () => {
    setNoGuess(true);
    message.info('Time Up');
  };

  useEffect(() => {
    if (messageBox.current) {
      messageBox.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
    if (props.gameState) {
      notification.success({
        key: 'joined',
        message: "Spectating",
        description: "When this turn ends you'll be in the game",
        placement: 'topLeft',
        top: 80,
        icon: <FormatPainterOutlined />,
      });
    }
  }, []);

  // Checks for canvas size changes
  useEffect(() => {
    if (prevDrawingWidth !== drawingWidth || prevDrawingHeight !== drawingHeight) {
      prevDrawingWidth = drawingWidth;
      prevDrawingHeight = drawingHeight;
      let payload = { ...canvas.current.getSaveData(), add: true, clear: true };
      socket.emit('pictochat', LZString.compress(JSON.stringify(payload)));
      message.info({ content: 'Please avoid changing your screen size mid game', key: 'resize' });
    }
  }, [drawingWidth, drawingHeight]);
  
  useEffect(() => {
    message.info("Welcome to Doodle!!");
    socket.on('chat message', msg => {
      setComments(comments => [...comments, msg]);
    });

    // Disable guessing again
    socket.on('guessed word', () => {
      setNoGuess(true);
      correctSound.play();
    });

    socket.on('pictochat', msg => {
      let decompMsg = JSON.parse(LZString.decompress(msg));
      console.log("Pictochat", decompMsg);
      if (decompMsg.add) {
        canvas.current.loadSaveData(decompMsg);
      } else if (decompMsg.undo) {
        canvas.current.undo();
      } else if (decompMsg.clear) {
        canvas.current.clear();
      }
    });

    socket.on('start round', msg => {
      almostOver = setTimeout(() => {
        tickerSound.play();
      }, (msg.end - 7) * 1000 - Date.now());
      canvas.current.clear(); // Clear the drawing
      setTimer(new Date(msg.end * 1000)); // Timer for the round
      setGameTimer(new Date(msg.gameTime * 1000)); // Remaining game time
      setNotTurn(true); // Not your turn
      setNoGuess(false); // Allow guesses
      setWord(msg.name);
      roundSound.play(); // Play new turn sound
      notification.success({
        key: 'drawWord',
        message: msg.name,
        description: 'Is drawing now!',
        placement: 'topLeft',
        top: 80,
        icon: <FormatPainterOutlined />,
      });
    });

    socket.on('get word', word => {
      // Load the your turn drawing
      canvas.current.loadSaveData(TurnDrawing);
      setWord(word);
      setChatOpen(false);
      setNoGuess(true);
      notification.success({
        key: 'drawWord',
        message: word,
        description: 'To the drawing board!',
        placement: 'topLeft',
        top: 80,
        icon: <FormatPainterOutlined style={{ color: '#5D22A1' }} />,
      });
      setTimeout(() => {
        canvas.current.clear();
        setNotTurn(false);
        turnSound.play();
      }, 3000);
    });

    socket.on('reveal word', word => {
      clearTimeout(almostOver); // Reset almost over sound
      tickerSound.stop();
      setWord(word);
      notification.success({
        message: 'The Word Was',
        description: word,
        icon: <SmileOutlined style={{ color: '#5D22A1' }} />,
        placement: 'topLeft',
        top: 80,
        duration: 2,
      });
    });

    socket.on('score', score => {
      setLeaderboard(score);
    });

    socket.on('end game', () => {
      message.success('Game over!!', 4);
      setTimer(new Date());
      clearTimeout(almostOver);
      endSound.play();
      setFeedbackOpen(true);
    });
  }, [socket]);

  const guessWord = guess => {
    if (guess.word.length > 0) {
      socket.emit('chat message', guess.word);
      form.resetFields();
      guessText.current.focus();
    }
  };

  const sendDrawing = msg => {
    if (!notTurn) socket.emit('pictochat', LZString.compress(JSON.stringify(msg)));
  };

  const resetCanvas = () => {
    canvas.current.clear();
    socket.emit('pictochat', LZString.compress(JSON.stringify({ clear: true })));
    setBrush('#000000');
    setBrushSize(2);
    setLazyRadius(0);
  };

  const marks = {
    1: { style: { color: brush }, label: <HighlightOutlined /> },
    16: { style: { color: brush }, label: <HighlightOutlined style={{ fontSize: 24, marginLeft: -4 }} /> },
  };
  const lazyMarks = {
    1: { style: { color: '#000' }, label: <SmallDashOutlined /> },
    50: { style: { color: '#000' }, label: <SmallDashOutlined style={{ fontSize: 24, marginLeft: -4 }} /> },
  };

  const highScore = useMemo(() => {
    let highScore = Math.max(...leaderboard.map(({ score }) => score));
    if (highScore === 0) highScore = -1;
    return highScore;
  }, [leaderboard]);

  return (
    <>
      <Layout style={{ minHeight: 'calc(100vh - 20px)', padding: 10, backgroundColor: '#f6f5f5' }}>
        <Row gutter={[20, 20]}>
          <Col xs={0} md={3}>
            <img src={Logo} alt="logo" className="logo" />
          </Col>
          <Col xs={6} sm={4}>
            <Countdown title="Turn Timer" value={timer} onFinish={timeUp} format="mm:ss" />
          </Col>
          <Col xs={12}>
            <Text type="secondary">{notTurn ? 'Drawing' : 'Word'}</Text>
            <Title level={4} style={{ marginTop: 7 }}>
              {word}
            </Title>
          </Col>
          <Col xs={0} sm={5}>
            <Countdown title="Game Ends In" value={gameTimer} format="mm:ss" />
          </Col>
          <Col xs={8} sm={0} style={{ textAlign: 'center' }}>
            <Button type="primary" disabled={!notTurn} onClick={() => setChatOpen(true)}>
              Guess
            </Button>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={0} md={3}>
            {leaderboard.map((player, ind) => (
              <div style={{ marginBottom: 15 }}>
                <Title level={5} className="nickname">
                  {player.score === highScore && <TrophyOutlined style={{ color: '#5d22a1' }} />} {player.nickname}
                </Title>
                <Text>{player.score}</Text>
              </div>
            ))}
          </Col>
          <Col xs={24} sm={16}>
            <CanvasDraw
              ref={canvas}
              loadTimeOffset={1}
              brushRadius={brushSize}
              brushColor={brush}
              lazyRadius={lazyRadius}
              canvasWidth={drawingWidth}
              canvasHeight={drawingHeight}
              style={{ borderRadius: 8 /*boxShadow: '4px 4px 4px #dddddd'*/ }}
              onChange={msg => !notTurn && sendDrawing(msg)}
              hideInterface={notTurn}
              disabled={notTurn}
            />
            <Row style={{ paddingTop: 20 }}>
              <div style={{ borderRadius: 8, width: drawingWidth, backgroundColor: '#fff', padding: 10 }}>
                <Row  gutter={[20, 20]}>
                  <Col xs={4} lg={4}>
                    <Popover content={<HexColorPicker color={brush} onChange={setBrush} />} title="Colour" trigger="click">
                      <Button type="primary" shape="circle" icon={<HighlightOutlined />} size="large" style={{ backgroundColor: brush }} />
                    </Popover>
                    <Title level={5}>Color</Title>
                  </Col>
                  <Col xs={10} lg={4}>
                    <Slider
                      defaultValue={brushSize}
                      step={0.1}
                      min={1}
                      max={16}
                      onAfterChange={value => setBrushSize(value)}
                      style={{ width: '80%' }}
                      marks={marks}
                    />
                  </Col>
                  <Col xs={10} lg={4}>
                    <Slider
                      defaultValue={lazyRadius}
                      step={1}
                      min={0}
                      max={50}
                      onAfterChange={value => setLazyRadius(value)}
                      style={{ width: '80%' }}
                      marks={lazyMarks}
                    />
                  </Col>
                  <Col xs={8} lg={4}>
                    <Button
                      type="primary"
                      disabled={notTurn}
                      icon={<UndoOutlined />}
                      onClick={() => {
                        canvas.current.undo();
                      }}
                    >
                      Undo
                    </Button>
                  </Col>
                  <Col xs={8} lg={4}>
                    <Button type="primary" ghost disabled={notTurn} icon={<ClearOutlined />} onClick={resetCanvas}>
                      Clear
                    </Button>
                  </Col>
                  <Col xs={8} lg={4} style={{ textAlign: 'center' }}>
                    <SoundOutlined
                      onClick={() => setMute(mute => !mute)}
                      className="muteInGame"
                      style={{ top: leaderboard.length > 0 && 40, color: mute ? '#AAAAAA' : '#5D22A1' }}
                    />
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
          <Col xs={0} sm={8} md={5}>
            <div className="message-container" style={{ height: drawingHeight }}>
              <div ref={messageBox} className="messagesScroller" style={{ height: (drawingHeight - 120)}}>
                {comments.length > 0 &&
                  comments.map(comment => (
                    <Comment author={comment.nickname} content={<p style={{ color: comment.color || 'inherit' }}>{comment.msg}</p>} />
                  ))}
              </div>
              <Form name="guess" form={form} onFinish={guessWord} className="guessForm" style={{ width: '95%' }}>
                <Form.Item name="word" style={{ marginBottom: 5 }}>
                  <Input placeholder="Guess The Word" ref={guessText} disabled={noGuess} autoComplete="off" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block disabled={noGuess}>
                    Guess
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
        <Drawer visible={chatOpen} onClose={() => setChatOpen(false)} className="chat-drawer">
          <Form name="guess" form={form} onFinish={guessWord} className="guessForm" style={{ width: '80%' }}>
            <Form.Item name="word" style={{ marginBottom: 5 }}>
              <Input placeholder="Guess The Word" ref={guessText} disabled={noGuess} autocomplete="off" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block disabled={noGuess}>
                Guess
              </Button>
            </Form.Item>
          </Form>
          <div ref={messageBox} className="mobile-messagesScroller">
            {comments.length > 0 &&
              comments.map(comment => (
                <Comment author={comment.nickname} content={<p style={{ color: comment.color || 'inherit' }}>{comment.msg}</p>} />
              ))}
          </div>
        </Drawer>
        <Modal
          title="Feedback"
          footer={null}
          centered
          className="feedback"
          visible={feedbackOpen}
          onCancel={() => {
            setFeedbackOpen(false);
            window.location.reload();
          }}
        >
          <iframe title="feedback" style={{ width: '100%', height: '70vh', border: 0 }} src={'https://app.afino.io/feedback?id=' + props.gameID} />
        </Modal>
      </Layout>
      <Footer style={{ textAlign: 'center', padding: '0 0', lineHeight: 1 }}>Afino ©2020 All Rights Reserved</Footer>
    </>
  );
}
